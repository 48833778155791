import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import {Bar} from 'react-chartjs-2';

import ReactJson from 'react-json-view';
import accounting from 'accounting';
import { Header, Main } from "../common";


import { financialService, TransactionService } from '../../services';


import 'semantic-ui-css/semantic.min.css';

import _ from 'lodash'
import {
    Table,
    Popup,
    Message,
    Grid,
    Modal,
    Icon,
    Transition,
    Segment,
    Statistic,
    Input,
    Dimmer,
    Loader,
    Label
} from 'semantic-ui-react';
import moment from 'moment';


export class FinanceAdmin extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            incomes: [],
            expenses: [],
            reversedTransactions: [],
            column: null,
            direction: null,
            syncingTransactions: false,
            syncMessage: null,
            showSyncMessage: null,
            infoMessage: null,
            currencyInfo: null,

            isLoading: false,
            searchResults: [],
            value: ''
        };

        this.fetchTransactions = this.fetchTransactions.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.handler = this.handler.bind(this);
    }

    async handler(currency) {

        try{

            let storedCurrencies = localStorage.getItem('currencyInfo') ? JSON.parse( localStorage.getItem('currencyInfo') ) : null;
            if(storedCurrencies && currency){
                storedCurrencies.selectedCurrency = currency;
                localStorage.setItem('currencyInfo', JSON.stringify(storedCurrencies));
                const currencyInfo = await financialService.getCurrencyRates();
                this.setState( { currencyInfo } );
            }

        } catch (e) {
            console.log(e);
        }

    }

    handleSearchChange = (e, { value }) => {

        const { transactions } = this.state;



        this.setState({ isLoading: true, value }, () => {

            setTimeout(() => {
                if (value.length < 1) {
                    return this.setState({isLoading: false, searchResults: [], value: ''});
                } else{

                    // Splitting result by space
                    const queries = value.split(' ');

                    const searchResults = transactions.filter(transaction => {

                        const str = JSON.stringify(transaction).toLowerCase();

                        const filteredQueries = queries.filter(query => {
                            return str.indexOf(query.toLowerCase()) !== -1
                        });

                        return filteredQueries.length;
                    });

                    return this.setState({isLoading: false, searchResults, value});

                }
            }, 200);

        });


    };

    hideMessage(){

        this.setState({showSyncMessage: false});

    }

    renderRow = (transaction, selectedCurrencyRate, selectedCurrencySymbol) => {

        let status;

        if(transaction.status === 'paid'){
            status = <Table.Cell textAlign='center' positive>{transaction.status}</Table.Cell>;
        } else if(transaction.status === 'cancelled'){
            status = <Table.Cell textAlign='center' negative>{transaction.status}</Table.Cell>;
        } else{
            status = <Table.Cell textAlign='center' warning>{transaction.status}</Table.Cell>;
        }


        return (
            <Table.Row key={transaction.date+Math.random()}>

                {transaction.project.name ? <Popup hoverable content={transaction.project.name} trigger={<Table.Cell textAlign='center'>{transaction.project.name}</Table.Cell>} /> : <Table.Cell textAlign='center'>{transaction.project.name}</Table.Cell>}

                <Table.Cell textAlign='center'>{transaction.client.name}</Table.Cell>
                <Table.Cell textAlign='center'>{accounting.formatMoney(transaction.paymentAmount * selectedCurrencyRate, selectedCurrencySymbol, 2, ",", ".")}</Table.Cell>

                {transaction.note ? <Popup hoverable content={transaction.note} trigger={<Table.Cell>{transaction.note}</Table.Cell>} /> : <Table.Cell>{transaction.note}</Table.Cell>}


                <Table.Cell textAlign='center'>{moment(transaction.date).format('DD MMM, YYYY')}</Table.Cell>
                {status}
                <Table.Cell textAlign='center'>
                    <Modal trigger={<Icon name='file alternate'/>}>
                        <Modal.Header>Attachment</Modal.Header>
                        <Modal.Content image scrolling>

                            <Modal.Description>
                                <ReactJson src={transaction} />
                            </Modal.Description>
                        </Modal.Content>

                    </Modal>
                </Table.Cell>



            </Table.Row>
        );

    };

    fetchTransactions = async function() {

        try{

            const transactions = await TransactionService.list();

            let incomes=[], expenses=[];

            transactions.forEach(transaction => {
                if(transaction.type === 'expenses') return expenses.push( transaction );
                if(transaction.type === 'incomes') return incomes.push( transaction );
            });

            this.setState({ syncingTransactions: false, transactions, reversedTransactions: [...transactions].reverse() })


        } catch (e) {
            console.log(e);
        }

    };

    async componentDidMount() {
        this.fetchTransactions();
        const currencyInfo = await financialService.getCurrencyRates();
        this.setState( { currencyInfo } );
    }



    getTotal = (transactions, type) => {

        return transactions.reduce((a, b) => {

            try {
                return b.type === type ? a + parseFloat( b.paymentAmount ) : a + 0;
            } catch (e) {
                return a + 0;
            }

        }, 0);

    };

    groupByMonths = (transactions, type) => {

        let groups = [];

        transactions.map(t => {

            if(t.type === type){

                const groupLabel = moment(t.date).format('MMM YYYY');

                const paymentAmount = t.paymentAmount ? parseFloat(t.paymentAmount) : 0;
                let groupIndex = 0;


                const isInGroups = groups.some((group, g) => {
                    if(group.label === groupLabel){
                        groupIndex = g;
                        return true;
                    }
                    return false;
                });

                if(!isInGroups){
                    groups.push({value: paymentAmount, label: groupLabel});
                } else{
                    groups[groupIndex].value += paymentAmount;
                }

            } else{
                return;
            }




        });


        return groups;

    };



    render() {

        const { transactions, searchResults, reversedTransactions, isLoading, value, results, syncingTransactions, syncMessage, showSyncMessage } = this.state;
        const { user, rootMethods: { setCurrency }, currencyInfo } = this.props;

        let selectedCurrencyRate, selectedCurrencySymbol;

        if(currencyInfo && currencyInfo.currencies && Object.keys(currencyInfo.currencies)){
            selectedCurrencyRate = currencyInfo.currencies[currencyInfo.selectedCurrency.toLowerCase()].rate || 1;
            selectedCurrencySymbol = currencyInfo.currencies[currencyInfo.selectedCurrency.toLowerCase()].symbol || '$';
        }

        let formattedData = {
            labels: _.union( transactions.map(t => {
                const date = moment(t.date);
                return date.isValid() ? date.format('MMM YYYY') : null;
            }).filter(label => {return label;}) ),
            datasets: [{
                data: [],
                backgroundColor: 'rgba(255, 99, 132, 1)',
                borderColor: 'rgb(255, 255, 255)',
                lineTension: 0,
                label: `Monthly Expenses (${selectedCurrencySymbol})`,
            },
            {
                data: [],
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgb(255, 255, 255)',
                lineTension: 0,
                label: `Monthly Incomes (${selectedCurrencySymbol})`,
            }
            ]

        };




        const totalIncomes = this.getTotal(transactions, 'incomes');
        const totalExpenses = this.getTotal(transactions, 'expenses');
        const incomesGroupedByMonths = this.groupByMonths(transactions, 'incomes');
        const expensesGroupedByMonths = this.groupByMonths(transactions, 'expenses');

        expensesGroupedByMonths.map(month => {
            // formattedData.datasets[0].data.push(month.value.toFixed(2));
            //formattedData.labels

            const labelIndex = formattedData.labels.indexOf(month.label);
            formattedData.datasets[0]['data'][labelIndex] = (month.value * selectedCurrencyRate).toFixed(2);



        });

        incomesGroupedByMonths.map(month => {
            const labelIndex = formattedData.labels.indexOf(month.label);
            formattedData.datasets[1]['data'][labelIndex] = (month.value * selectedCurrencyRate).toFixed(2);
        });


        const incomesData = {
            monthlyAverage: totalIncomes / formattedData.labels.length,
            weeklyAverage: (totalIncomes / formattedData.labels.length) / 4.34524,
            dailyAverage: ( (totalIncomes / formattedData.labels.length) / 4.34524 ) / 7
        };

        const expensesData = {
            monthlyAverage: totalExpenses / formattedData.labels.length,
            weeklyAverage: (totalExpenses / formattedData.labels.length) / 4.34524,
            dailyAverage: ( (totalExpenses / formattedData.labels.length) / 4.34524 ) / 7
        };





        return (

            <Fragment>

                {!transactions.length ? <Dimmer page={true} active><Loader indeterminate size='medium'>Loading</Loader></Dimmer> : ''}

                <Header setCurrency={setCurrency} currencyInfo={currencyInfo} user={user} {...this.props} />
                <Main>


                    <Grid stackable rows={3} columns={1}>


                        <Grid.Row>

                            <Grid.Column>

                                <Segment basic>

                                    {/*<Label as='a' color='blue' ribbon>*/}
                                    {/*    Total*/}
                                    {/*</Label>*/}

                                    <Statistic.Group widths={3}>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(totalIncomes * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Total Incomes</Statistic.Label>
                                        </Statistic>



                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(totalExpenses * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Total Expenses</Statistic.Label>
                                        </Statistic>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney((totalIncomes - totalExpenses)*selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Total Profit</Statistic.Label>
                                        </Statistic>



                                    </Statistic.Group>


                                </Segment>

                            </Grid.Column>



                        </Grid.Row>


                        <Grid.Row >

                            <Grid.Column>

                                <Segment>
                                    <Bar
                                        data={formattedData}
                                    />
                                </Segment>


                            </Grid.Column>



                        </Grid.Row>

                        {/*incomes*/}
                        <Grid.Row>

                            <Grid.Column>

                                <Segment>

                                    <Label as='a' color='green' ribbon>
                                        Incomes
                                    </Label>

                                    <Statistic.Group widths={3}>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(incomesData.monthlyAverage * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Monthly Average</Statistic.Label>
                                        </Statistic>



                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(incomesData.weeklyAverage * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Weekly Average</Statistic.Label>
                                        </Statistic>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(incomesData.dailyAverage * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Daily Average</Statistic.Label>
                                        </Statistic>



                                    </Statistic.Group>


                                </Segment>

                            </Grid.Column>





                        </Grid.Row>

                        {/*expeses*/}
                        <Grid.Row>

                            <Grid.Column>

                                <Segment>

                                    <Label as='a' color='red' ribbon>
                                        Expenses
                                    </Label>

                                    <Statistic.Group widths={3}>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(expensesData.monthlyAverage * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Monthly Average</Statistic.Label>
                                        </Statistic>



                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(expensesData.weeklyAverage * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Weekly Average</Statistic.Label>
                                        </Statistic>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(expensesData.dailyAverage * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Daily Average</Statistic.Label>
                                        </Statistic>



                                    </Statistic.Group>


                                </Segment>


                            </Grid.Column>

                        </Grid.Row>

                        {/*net*/}
                        <Grid.Row>

                            <Grid.Column>

                                <Segment>

                                    <Label as='a' color='purple' ribbon>
                                        Net
                                    </Label>

                                    <Statistic.Group widths={3}>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney((incomesData.monthlyAverage - expensesData.monthlyAverage) * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Monthly Average</Statistic.Label>
                                        </Statistic>



                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney((incomesData.weeklyAverage - expensesData.weeklyAverage) * selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Weekly Average</Statistic.Label>
                                        </Statistic>

                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney((incomesData.dailyAverage - expensesData.dailyAverage)*selectedCurrencyRate, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Daily Average</Statistic.Label>
                                        </Statistic>



                                    </Statistic.Group>


                                </Segment>

                            </Grid.Column>

                        </Grid.Row>

                        {/*transactions*/}
                        <Grid.Row>

                            <Grid.Column>


                                {/*Search*/}
                                <Input
                                    onChange={_.debounce(this.handleSearchChange, 500)}
                                    size='large'
                                    loading={isLoading}
                                    icon='search' placeholder='Search...' />
                                <Transition visible={showSyncMessage} animation='tada' duration={777}>
                                    <Message hidden={!showSyncMessage}
                                             onDismiss={this.hideMessage}
                                             positive>
                                        <Message.Header>Transactions successfully synced!</Message.Header>
                                        <p>{syncMessage}</p>
                                    </Message>
                                </Transition>


                                {/*<Button floated={"right"} size={"large"} disabled icon labelPosition='left'>*/}
                                {/*    <Icon name='download' /> Export*/}
                                {/*</Button>*/}


                                </Grid.Column>

                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <Table selectable celled fixed singleLine basic>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign='center'>
                                            Project</Table.HeaderCell>

                                        <Table.HeaderCell textAlign='center'>
                                            Client
                                        </Table.HeaderCell>

                                        <Table.HeaderCell textAlign='center'>
                                            Payment Amount
                                        </Table.HeaderCell>

                                        <Table.HeaderCell textAlign='center'>
                                            Note
                                        </Table.HeaderCell>

                                        <Table.HeaderCell textAlign='center'>
                                            Date
                                        </Table.HeaderCell>

                                        <Table.HeaderCell textAlign='center'>
                                            Status
                                        </Table.HeaderCell>

                                        <Table.HeaderCell textAlign='center'>
                                            Attachment
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>

                                    {!value.length ? _.map(reversedTransactions, transaction => (
                                        this.renderRow(transaction, selectedCurrencyRate, selectedCurrencySymbol)
                                    )) : (searchResults.length ? _.map(searchResults, transaction => (

                                        this.renderRow(transaction, selectedCurrencyRate, selectedCurrencySymbol)

                                    )) : (isLoading ? <Table.Row><Table.Cell textAlign={"center"} colSpan={7} ><Loader active inline='centered' /></Table.Cell></Table.Row> : <Table.Row><Table.Cell textAlign={"center"} colSpan={7} >Not found</Table.Cell></Table.Row>))}

                                </Table.Body>
                            </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>


                </Main>



            </Fragment>

        );
    }


}


export default connect(null)(FinanceAdmin);








