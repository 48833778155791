// import { baseURL } from '../config';
import _ from "lodash";
import { OAuthService } from "./OAuthService";

import rp from "request-promise";
const baseURL = "https://dev.botsculptors.com";


class Project {

    add(project){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'POST',
                url: `${baseURL}/projects/add`,
                headers: {
                    authorization: idToken
                },
                body: project,
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }

    update(project){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'PUT',
                url: `${baseURL}/projects/update`,
                headers: {
                    authorization: idToken
                },
                body: project,
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }

    delete(projectId){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'DELETE',
                url: `${baseURL}/projects/delete`,
                headers: {
                    authorization: idToken
                },
                body: {projectId},
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }

    list(){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'GET',
                url: `${baseURL}/projects/list`,
                headers: {
                    authorization: idToken
                },
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }

}

export const ProjectService = new Project();
