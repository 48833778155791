import React, { Fragment, PureComponent } from 'react';
import {Line} from 'react-chartjs-2';

import ReactJson from 'react-json-view';
import accounting from 'accounting';
import { Header, Main } from "../common";

import styled from "styled-components";

import { financialService, TransactionService } from '../../services';

import { CSVLink } from "react-csv";

import 'semantic-ui-css/semantic.min.css';

import _ from 'lodash'

import { Table, Popup, Grid, Modal, Icon, Button, Segment, Statistic, Input, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import moment from 'moment';

const StyledTable = styled(Table)`
  overflow: visible;
  

  
  thead th{
    position: -webkit-sticky;
    position: sticky;
    top: 44px;
    z-index: 9;
  }
  
  tfoot th{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 9;
  }
  
`;


export class Finance extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            selectedTransactions: [],
            transactionsForExport: [],
            reversedTransactions: [],
            column: null,
            direction: null,
            currencyInfo: null,
            isLoading: false,
            searchResults: [],
            value: '',
            isChecked: false,
            isSelectAllChecked: false
        };
        this.fetchTransactions = this.fetchTransactions.bind(this);
        this.handler = this.handler.bind(this);
        // this.handleTransactionSelectChange = this.handleTransactionSelectChange.bind(this);

    }

    async handler(currency) {

        try{

            let storedCurrencies = localStorage.getItem('currencyInfo') ? JSON.parse( localStorage.getItem('currencyInfo') ) : null;
            if(storedCurrencies && currency){
                storedCurrencies.selectedCurrency = currency;
                localStorage.setItem('currencyInfo', JSON.stringify(storedCurrencies));
                const currencyInfo = await financialService.getCurrencyRates();
                this.setState( { currencyInfo } );
            }

        } catch (e) {
            console.log(e);
        }

    }


    handleSearchChange = (e, { value }) => {

        const { transactions } = this.state;
        
        this.setState({ isLoading: true, value }, () => {

            setTimeout(() => {
                if (value.length < 1) {
                    return this.setState({isLoading: false, searchResults: [], value: ''});
                } else{

                    // Splitting result by space
                    const queries = value.split(' ');

                    const searchResults = transactions.filter(transaction => {

                        const str = JSON.stringify(transaction).toLowerCase();

                        const filteredQueries = queries.filter(query => {
                            return str.indexOf(query.toLowerCase()) !== -1
                        });

                        return filteredQueries.length;
                    });

                    return this.setState({isLoading: false, searchResults, value});

                }
            }, 200);

        });


    };


    renderRow = (transaction, selectedCurrencyRate, selectedCurrencySymbol) => {

        let status;

        if(transaction.status === 'paid'){
            status = <Table.Cell textAlign='center' positive>{transaction.status}</Table.Cell>;
        } else if(transaction.status === 'cancelled'){
            status = <Table.Cell textAlign='center' negative>{transaction.status}</Table.Cell>;
        } else{
            status = <Table.Cell textAlign='center' warning>{transaction.status}</Table.Cell>;
        }

        return (
            <Table.Row key={transaction.date+Math.random()}>

                <Table.Cell textAlign='center'>
                    <Checkbox
                        checked={_.get(transaction, "isSelected") || false}
                        onClick={(event, selectEl) => this.handleTransactionSelectChange(selectEl, transaction.id)} />
                </Table.Cell>

                {transaction.project.name ? <Popup hoverable content={transaction.project.name} trigger={<Table.Cell textAlign='center'>{transaction.project.name}</Table.Cell>} /> : <Table.Cell textAlign='center'>{transaction.project.name}</Table.Cell>}

                <Table.Cell textAlign='center'>{transaction.client.name}</Table.Cell>

                <Table.Cell textAlign='center'>{accounting.formatMoney(transaction.paymentAmount * selectedCurrencyRate, selectedCurrencySymbol, 2, ",", ".") }</Table.Cell>

                {transaction.note ? <Popup hoverable content={transaction.note} trigger={<Table.Cell>{transaction.note}</Table.Cell>} /> : <Table.Cell>{transaction.note}</Table.Cell>}


                <Table.Cell textAlign='center'>{moment(transaction.date).format('DD MMM, YYYY')}</Table.Cell>

                {status}


            </Table.Row>
        );

    };


    async componentDidMount() {
        this.fetchTransactions();
    }

    fetchTransactions = async () => {

        try{

            let transactions = await TransactionService.list();

            // transactions
            // transactions = transactions.slice(0, 1);

            const transactionsForExport = transactions.map(transaction => {
                return {
                    'Project': transaction.project.name,
                    'Client': transaction.client.name,
                    'Payment Amount': transaction.paymentAmount,
                    'Note': transaction.note,
                    'Date': transaction.date,
                    'Status': transaction.status
                }
            });

            this.setState({transactions, transactionsForExport, reversedTransactions: [...transactions].reverse()});


        } catch (e) {
            console.log(e);
        }


    };



    messageSupport(url='') {
        window.open(url, '_blank');
    }

    getMonths(start, end, months) {

        if ( start.isBefore(end, 'month') ){
            months.push( {value: 0, label: start.format('MMM YYYY')} );
            return this.getMonths( start.add(1, 'months'), end, months );
        }

        return months;

    }

    handleTransactionSelectChange(selectEl, transactionId) {


            const isChecked = _.get(selectEl, 'checked') || false;


            // Select all / Unselect all
            if( (isChecked || !isChecked) && transactionId === 'select__all'){


                this.setState(prevState => {

                    const transactions = prevState.transactions.map(transaction => {
                        _.set(transaction, "isSelected", isChecked);
                        return transaction;
                    });

                    return {
                        ...prevState,
                        transactions,
                        isSelectAllChecked: isChecked
                    }
                });

                return;


            }


            // Add transaction to selected transactions / Remove transaction from selected transactions
            this.setState(prevState => {

                const transactions = prevState.transactions.map(transaction => {
                    if(transaction.id === transactionId) _.set(transaction, "isSelected", isChecked);
                    return transaction;
                });

                return {
                    ...prevState,
                    transactions
                }
            });





    }

    render() {


        const { transactions, transactionsForExport, searchResults, reversedTransactions, isLoading, value } = this.state;
        const { user, rootMethods: { setCurrency }, currencyInfo } = this.props;


        const joinDate = moment( _.get(user, "attributes.metadata.joinDate") );
        const momentNow = moment().add(1, 'months');
        const groups = this.getMonths(joinDate, momentNow, []);

        let selectedCurrencyRate, selectedCurrencySymbol;

        if(currencyInfo && currencyInfo.currencies && Object.keys(currencyInfo.currencies)){
            selectedCurrencyRate = currencyInfo.currencies[currencyInfo.selectedCurrency.toLowerCase()].rate || 1;
            selectedCurrencySymbol = currencyInfo.currencies[currencyInfo.selectedCurrency.toLowerCase()].symbol || '$';
        }

        let formattedData = {
            labels: [],
            datasets: [{
                data: [],
                borderWidth: 0.8,
                backgroundColor: 'rgba(221, 221, 221, 0.3)',
                borderColor: 'rgb(31, 76, 157)',
                lineTension: 0,
                label: `Monthly Income (${selectedCurrencySymbol})`,
            }]
        };




        reversedTransactions.map(transaction => {
            const groupLabel = moment(transaction.date).format('MMM YYYY');
            let groupIndex = 0;
            const paymentAmount = transaction.paymentAmount ? parseFloat(transaction.paymentAmount) * selectedCurrencyRate : 0;
            const isInGroups = groups.some((group, g) => {
                if(group.label === groupLabel){
                    groupIndex = g;
                    return true;
                }
                return false;
            });
            if(!isInGroups){
                groups.push({value: paymentAmount, label: groupLabel});
            } else{
                groups[groupIndex].value += paymentAmount;
            }
        });
        groups.map(group => {
            formattedData.labels.push(group.label);
            formattedData.datasets[0].data.push(group.value.toFixed(2));
        });


        let totalEarnings=0;

        groups.map((group, g) => {
            totalEarnings += group.value || 0;
        });


        let paymentAmountSum = 0;
        let totalSelected = 0;
        transactions.forEach(transaction => {
            if( _.get(transaction, "isSelected") ) {
                totalSelected += 1;
                paymentAmountSum += _.get(transaction, "paymentAmount") || 0;
            }
        });


        const tbodyEl = function (){

            return (<tr>
                <th>Left Column</th>
                <td>Cell content</td>
                <td>Cell content longer</td>
                <td>Cell content</td>
                <td>Cell content</td>
                <td>Cell content</td>
                <td>Cell content</td>
                <td>Cell content</td>
            </tr>)
        }


        return (

            <Fragment>


                {!transactions.length ? <Dimmer page={true} active><Loader indeterminate size='medium'>Loading</Loader></Dimmer> : ''}

                <Header setCurrency={setCurrency} currencyInfo={currencyInfo} user={user} {...this.props} />


                <Main>


                    <Grid stackable rows={3} columns={1}>


                        <Grid.Row >

                            <Grid.Column>

                                <Segment basic>


                                    <Statistic.Group widths={4} size={'small'}>
                                        <Statistic >
                                            <Statistic.Value>{accounting.formatMoney(totalEarnings, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Total Earnings</Statistic.Label>
                                        </Statistic>
                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney((totalEarnings / groups.length), selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Monthly Average</Statistic.Label>
                                        </Statistic>
                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney((totalEarnings / groups.length) / 4.34524, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Weekly Average</Statistic.Label>
                                        </Statistic>
                                        <Statistic>
                                            <Statistic.Value>{accounting.formatMoney(((totalEarnings / groups.length) / 4.34524) / 7, selectedCurrencySymbol, 0, ",", ".")}</Statistic.Value>
                                            <Statistic.Label>Daily Average</Statistic.Label>
                                        </Statistic>

                                    </Statistic.Group>

                                </Segment>


                            </Grid.Column>





                        </Grid.Row>

                        <Grid.Row >

                            <Grid.Column>

                                <Segment>
                                    <Line
                                        data={formattedData}
                                        options={{
                                            hover: {
                                                animationDuration: 0
                                            },
                                            animation: {
                                                onComplete: function () {
                                                    let ctx = this.chart.ctx;
                                                    ctx.font = '11px Arial';
                                                    ctx.fillStyle = "black";
                                                    ctx.textAlign = 'center';
                                                    ctx.textBaseline = 'bottom';
                                                    this.data.datasets.forEach(function (dataset)
                                                    {
                                                        for (let i = 0; i < dataset.data.length; i++) {
                                                            for(let key in dataset._meta)
                                                            {
                                                                let model = dataset._meta[key].data[i]._model;
                                                                ctx.fillText(`${dataset.data[i]}`, model.x, model.y - 10);
                                                            }
                                                        }
                                                    });
                                                },
                                                duration: 0
                                            }
                                        }}
                                    />
                                </Segment>



                            </Grid.Column>





                        </Grid.Row>


                        <Grid.Row>

                            <Grid.Column>


                                <div className={"transactionFilterGroup"}>
                                    <Input
                                        onChange={_.debounce(this.handleSearchChange, 500)}

                                        loading={isLoading}
                                        className={"transactionSearchInput"}
                                        icon='search' placeholder='Search...' />


                                            <Popup hoverable content={'If button does not work - reach out @torrichel in Telegram.'}
                                                   trigger={<Button size={"medium"} onClick={this.messageSupport.bind(this, _.get(user, "attributes.metadata.supportLink"))} icon primary labelPosition='left'><Icon name='paper plane'/>Support</Button>} />


                                            <CSVLink
                                                data={transactionsForExport}
                                                filename={`BTS_INCOME_STATEMENT_${new Date().toISOString()}.csv`}
                                                target="_blank">

                                                <Button positive icon labelPosition='left'  size={"medium"}>
                                                    <Icon name='download' /> Export
                                                </Button>

                                            </CSVLink>
                                </div>




                            </Grid.Column>


                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column >

                                {/*TODO make table footer fixed and colorized // selectable celled fixed singleLine */}
                                <StyledTable
                                    selectable celled fixed singleLine
                                    style={{"margin-bottom": "45px", "position": "relative", "table-layout": "fixed"}}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell

                                                textAlign='center'>
                                                <Checkbox
                                                    checked={this.state.isSelectAllChecked}
                                                    onChange={(e, selectEl) => this.handleTransactionSelectChange(selectEl, 'select__all')}/>
                                            </Table.HeaderCell>
                                            
                                            <Table.HeaderCell

                                                textAlign='center'>
                                                Project</Table.HeaderCell>

                                            <Table.HeaderCell

                                                textAlign='center'>
                                                Client
                                            </Table.HeaderCell>

                                            <Table.HeaderCell

                                                textAlign='center'>
                                                Payment Amount
                                            </Table.HeaderCell>

                                            <Table.HeaderCell

                                                textAlign='center'>
                                                Note
                                            </Table.HeaderCell>

                                            <Table.HeaderCell

                                                textAlign='center'>
                                                Date
                                            </Table.HeaderCell>

                                            <Table.HeaderCell

                                                textAlign='center'>
                                                Status
                                            </Table.HeaderCell>

                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>

                                        {!value.length ? _.map(reversedTransactions, transaction => (
                                            this.renderRow(transaction, selectedCurrencyRate, selectedCurrencySymbol)
                                        )) : (searchResults.length ? _.map(searchResults, transaction => (

                                            this.renderRow(transaction, selectedCurrencyRate, selectedCurrencySymbol)

                                        )) : (isLoading ? <Table.Row><Table.Cell textAlign={"center"} colSpan={7} ><Loader active inline='centered' /></Table.Cell></Table.Row> : <Table.Row><Table.Cell textAlign={"center"} colSpan={7} >Not found</Table.Cell></Table.Row>))}

                                    </Table.Body>

                                    {totalSelected > 0 && (<Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign='center'><b>Selected: {totalSelected}</b></Table.HeaderCell>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell textAlign='center'><b>{accounting.formatMoney(paymentAmountSum * selectedCurrencyRate, selectedCurrencySymbol, 2, ",", ".")}</b></Table.HeaderCell>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell/>

                                        </Table.Row>
                                    </Table.Footer>)}


                                </StyledTable>

                            </Grid.Column>
                        </Grid.Row>

                    </Grid>


                </Main>


            </Fragment>

        );
    }


}


export default Finance
