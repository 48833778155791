import React, { Component }  from 'react';
import { Image, Menu, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

import styled from 'styled-components';

import { OAuthService } from "../../services";


const Wrapper = styled.div`

      padding: 0 ${({theme}) => theme.contentPaddingDesktop};


      ${({theme}) => theme.mobile`
            padding: 0 ${props => props.theme.contentPaddingMobile};
      `}

      ${({theme}) => theme.tablet`
            padding: 0 ${props => props.theme.contentPaddingTablet};
      `}
      
      // .ui.menu .ui.dropdown .menu>.item.message_dropdown_container:hover{
      //   background: #fff!important;
      // }

`;

const StyledHeaderMenu = styled(Menu)`

      padding: 0 ${({theme}) => theme.contentPaddingDesktop};
      height: 44px!important;
      background: rgba(0,0,0,0.9)!important;
      backdrop-filter: saturate(180%) blur(20px)!important;
      z-index: 500;
      
      .avatarItem{
        padding-right: 0!important;
          img{
          width: 30px!important;
         
        }
      }
      
      .active{
        background: rgba(255,255,255,.1)!important;
      }
      .currencyDropdown{
        background: rgba(255,255,255,1)!important;
      }
      
      .logo{
        padding-left: 0!important;
        padding-right: 0!important;
        margin-right: 20px!important;
        img{
          width: 30px!important;
          transform: rotate(-13deg);
        }
      }
      .logo:hover{
        background: none!important;
      }

      ${({theme}) => theme.mobile`
            padding: 0 ${props => props.theme.contentPaddingMobile};
      `}

      ${({theme}) => theme.tablet`
            padding: 0 ${props => props.theme.contentPaddingTablet};
      `}
      
      .item:hover{
        background: transparent!important;
        color: #fff!important;
      }
      
      .ui.menu .ui.dropdown .menu>.item.message_dropdown_container:hover{
        background: #fff!important;
      }
      
      

`;


export class Header extends Component {

    constructor(props){
        super(props);

        this.state = {
            open: false,
            activeItem: _.capitalize( _.get(props, 'history.location.pathname', '').replace(/\//g, '') )
        };

        // console.log( _.capitalize( _.get(props, 'history.location.pathname', '').replace(/\//g, '') ) )
        this.handleItemClick = this.handleItemClick.bind(this);

    }


    handleItemClick(e, { path }) {
        if(this.props.user && path) this.props.history.push(path);
    };

    handleChange = async (e, {value}) => {
        if(value === 'sign out') await OAuthService.signOut();
    };


    setCurrency = (e, {value}) => {
        this.props.setCurrency(value);
    };

    messageSupport(url='') {
        window.open(url, '_blank');
    }

    openLink(url='') {
        return window.open(url, '_blank');
    }


    render(){

        const { currencyInfo, user} = this.props;
        const { activeItem } = this.state;
        // console.log(this.props, "header props")

        const currencies = (currencyInfo && currencyInfo.currencies && Object.keys(currencyInfo.currencies).length) ?
            Object.keys(currencyInfo.currencies).map(key => {

                return {
                    key: key.toUpperCase(),
                    text: currencyInfo.currencies[key].symbol,
                    value: key.toUpperCase(),
                    content: currencyInfo.currencies[key].symbol
                }

            }) : null;



        return (

            <Wrapper>

                <StyledHeaderMenu size={'small'}
                      color={'black'}
                      fixed={'top'}
                      inverted={true}
                      borderless={true}
                      stackable={false}>


                    <Menu.Item
                        header
                        name='Finance'
                        onClick={this.handleItemClick}
                        path="/finance"
                        className="logo"
                    >
                        <Image src={"https://botsculptors.s3.amazonaws.com/logo-head.png"} />

                    </Menu.Item>



                    <Menu.Item
                        name='Projects'
                        onClick={this.handleItemClick}
                        path="/projects/list"
                    />

                    {_.get(user, "isSuperAdmin") ? <Menu.Item
                        name='Clients'
                        // active={activeItem === 'Clientslist'}
                        onClick={this.handleItemClick}
                        path="/clients/list"
                    /> : ""}

                    <Menu.Item
                        name='Finance'
                        // active={activeItem === 'Finance'}
                        onClick={this.handleItemClick}
                        path="/finance"
                    />


                    {/*<Dropdown item text='Upwork' icon={null}>*/}
                    {/*    <Dropdown.Menu>*/}

                    {/*        <Dropdown.Item*/}
                    {/*            onClick={this.openLink.bind(this, 'https://www.upwork.com/agencies/~01db6745cb7a665aba')}>*/}
                    {/*            Agency*/}
                    {/*        </Dropdown.Item>*/}

                    {/*        <Dropdown.Item*/}
                    {/*            onClick={this.openLink.bind(this, _.get(user, "attributes.metadata.upwork", "#"))}>*/}
                    {/*            Profile*/}
                    {/*        </Dropdown.Item>*/}

                    {/*    </Dropdown.Menu>*/}
                    {/*</Dropdown>*/}




                    <Menu.Menu position='right'>
                        <Dropdown icon={null} item text={_.get(currencyInfo, "selectedCurrency","")}>
                            <Dropdown.Menu>
                                {_.map(currencies, currency => (
                                    <Dropdown.Item
                                        key={currency.key}
                                        onClick={this.setCurrency}
                                        value={currency.value}>{currency.key}</Dropdown.Item>))}
                            </Dropdown.Menu>
                        </Dropdown>



                        <Menu.Item className={"avatarItem"}>
                            <Dropdown
                                trigger={<span><Image src={_.get(user, "attributes.picture")} rounded={true} /></span>}
                                pointing='top right'
                                icon={null}>

                                <Dropdown.Menu>
                                    <Dropdown.Item content={<span>Signed in as <strong>{_.get(user, "attributes.name")}</strong></span>} />

                                    <Dropdown.Item
                                        key={'sign-out'}
                                        text={'Sign Out'}
                                        icon={'sign out'}
                                        value={'sign out'}
                                        onClick={this.handleChange}
                                    />


                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>




                </StyledHeaderMenu>

            </Wrapper>





        );

    }


}
