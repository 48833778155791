import { baseURL } from '../config';
import axios from 'axios';
import _ from 'lodash';


import moment from 'moment';



// export const getTransactions = async () => {
//
//     return new Promise(resolve => {
//
//         axios.get(`${baseURL}/transactions`, {headers: {'Authorization': `Bearer ${localStorage.getItem('jwt')}`}})
//             .then(response => {
//
//                 const { data } = response.data;
//                 return resolve(data);
//
//             })
//             .catch(error => {
//
//
//                 // Log out if user is unauthorized
//                 if( error.response.status === 401 ){
//                     authService.logout();
//                 } else{
//                     return resolve([]);
//                 }
//
//             });
//
//     });
//
//
// };
// export const syncTransactions = async () => {
//
//     return new Promise(resolve => {
//
//         axios.get(`${baseURL}/transactions/sync`, {headers: {'Authorization': `Bearer ${localStorage.getItem('jwt')}`}})
//             .then(response => {
//                 const { data } = response;
//                 return resolve(data);
//             })
//             .catch(error => {
//
//                 console.log(error);
//
//                 // Log out if user is unauthorized
//                 if( error.response.status === 401 ){
//                     authService.logout();
//                 } else{
//                     resolve(null);
//                 }
//
//             });
//
//     });
//
//
// };

export const getCurrencyRates = () => {

    return new Promise(async (resolve, reject) => {

        try{

            const storedCurrencies = localStorage.getItem('currencyInfo') ? JSON.parse( localStorage.getItem('currencyInfo') ) : null;
            const isUpdatedToday = storedCurrencies ? moment().isSame(moment(storedCurrencies.updatedAt), 'day') : null;
            const currencyTemplateObject = (eur, gel, rub) => {
                return {
                    usd: {
                        rate: 1,
                        symbol: '$'
                    }, eur: {
                        rate: eur,
                        symbol: '€'
                    }, gel: {
                        rate: gel,
                        symbol: '₾'
                    }, rub: {
                        rate: rub,
                        symbol: '₽'
                    }
                };
            };

            // check for last update
            if(isUpdatedToday){
                return resolve(storedCurrencies)
            } else{

                const currencies = await axios.get(`https://openexchangerates.org/api/latest.json?app_id=f867c8a77db84cb6926c1f1382be7e5b`)
                    .then(response => {
                        // console.log(response);
                        const rates = _.get(response, "data.rates");
                        const USD_EUR = _.get(rates, "EUR", 0);
                        const USD_GEL = _.get(rates, "GEL", 0);
                        const USD_RUB = _.get(rates, "RUB", 0);
                        return currencyTemplateObject(USD_EUR, USD_GEL, USD_RUB);
                    })
                    .catch(error => {
                        console.log(error);
                        return currencyTemplateObject(0, 0, 0);
                    });


                const currencyInfo = {
                    updatedAt: moment().toISOString(),
                    currencies,
                    selectedCurrency: storedCurrencies ? storedCurrencies.selectedCurrency : 'USD'
                };
                localStorage.setItem('currencyInfo', JSON.stringify(currencyInfo));

                return resolve(currencyInfo);


            }

        } catch (e) {
            return reject(e);
        }

    });


};



export const financialService = {
    getCurrencyRates
};
