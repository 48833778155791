import React, { Component, Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Header, Main } from "../common";
import faker from "faker";
import _ from 'lodash';
import { ProjectService, UserService, ClientService } from "../../services";
import 'semantic-ui-css/semantic.min.css';


import {
    Icon,
    Feed,
    Loader,
    Modal,
    Image,
    Select,
    Button,
    Dropdown,
    Grid,
    Header as HeaderSemanticUI,
    Form,
    Confirm,
    Card,
    Dimmer, Input, Container, Label
} from 'semantic-ui-react';



import styled from 'styled-components';
import accounting from "accounting";

const ControlPanel = styled.div`
    display: flex;
    //justify-content: space-between;
    
`;

const StyledCard = styled(Card)`
    //display: flex;
    .statusIndicatorCircle{
      margin-right: 5px!important;
    }
`;




export class ListAdmin extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            closeOnEscape: true,
            openEdit: false,
            deleteConfirmPopupOpen: false,
            project: {},
            projects: [],
            users: [],
            clients: [],
            search: {
                isLoading: false,
                value: "",
                results: []
            }
        };

    }


    renderCard = project => {

        const { currencyInfo } = this.props;
        const selectedCurrencyCode = _.get(currencyInfo, "selectedCurrency", "").toLowerCase();

        const selectedCurrencyRate = _.get(currencyInfo, `currencies[${selectedCurrencyCode}].rate`, 1);
        const selectedCurrencySymbol = _.get(currencyInfo, `currencies[${selectedCurrencyCode}].symbol`, "$");

        const totalEarned = accounting.formatMoney(project.totalEarned * selectedCurrencyRate, selectedCurrencySymbol, 2, ",", ".");

        const upworkChatLink = _.get(project, "upwork.chatLink");
        const telegramChatLink = _.get(project, "telegram.chatLink");


        return (
            <StyledCard key={project.id} >

                <Card.Content>

                    <Card.Header>
                        <Label circular color={project.status === "opened" ? "green" : "grey"} empty className={"statusIndicatorCircle"}/> {project.name}
                    </Card.Header>
                    {/*<Card.Meta>{_.get(project, "client.name")}</Card.Meta>*/}
                    <Card.Description>



                        <Feed>


                            {_.get(project, "users", []).map(user => (

                                <Feed.Event key={_.get(user, "email")} >
                                    <Feed.Label image={_.get(user, "picture")} />

                                    <Feed.Content>
                                        <Feed.Summary content={_.get(user, "name")} />
                                    </Feed.Content>

                                </Feed.Event>

                            ))}

                        </Feed>


                    </Card.Description>
                </Card.Content>

                {upworkChatLink ? <Card.Content extra>
                    <a target={"_blank"} href={upworkChatLink}><Icon name='discussions' /> Upwork</a>
                </Card.Content> : ""}

                {telegramChatLink ? <Card.Content extra>
                    <a target={"_blank"} href={telegramChatLink}><Icon name='telegram' /> Telegram</a>
                </Card.Content> : ""}

                <Card.Content extra>
                    <Icon name='money' /> {totalEarned}
                </Card.Content>
                <Card.Content extra>
                    <Icon name='user' /> {_.get(project, "client.name")}
                </Card.Content>
                <Card.Content extra>
                    <Button.Group basic fluid>

                        <Button animated onClick={this.openEdit.bind(this, project)}>
                            <Button.Content visible>Edit</Button.Content>
                            <Button.Content hidden>
                                <Icon name='edit' />
                            </Button.Content>
                        </Button>

                        <Button animated onClick={this.deleteConfirmPopupOpen.bind(this, project.id)}>
                            <Button.Content visible>Delete</Button.Content>
                            <Button.Content hidden>
                                <Icon name='trash' />
                            </Button.Content>
                        </Button>

                    </Button.Group>
                </Card.Content>
            </StyledCard>
        );

    };

    handleChange = (e, { name, value }) => {

        let project = this.state.project;

        if(name === "upworkChatLink"){
            _.set(project, "upwork.chatLink", (value || null));
        } else if(name === "telegramChatId"){
            _.set(project, "telegram.chatId", (value || null));
        } else if(name === "telegramChatLink"){
            _.set(project, "telegram.chatLink", (value || null));
        } else{
            _.set(project, name, value);
        }

        this.setState({ project });
    };

    handleSubmit = async () => {

        const project = {
            ..._.omit(this.state.project, ["upworkChatLink", "telegramChatId", "telegramChatLink", "totalEarned", "users"])
        };

        const newProject = await ProjectService.add( project );
        const projects = this.state.projects;
        projects.push(newProject);
        this.setState({ projects, project: {}, open: false });
    };

    handleEditSubmit = async () => {

        const project = {
            ..._.omit(this.state.project, ["upworkChatLink", "telegramChatId", "telegramChatLink", "totalEarned", "users"])
        };


        const updatedProject = await ProjectService.update( project );
        const projects = this.state.projects.map(project => project.id === updatedProject.id ? updatedProject : project );
        this.setState({ projects, project: {}, openEdit: false });
    };

    close = () => this.setState({ open: false, openEdit: false });
    open = () => this.setState({ open: true });
    deleteConfirmPopupOpen = projectId => this.setState({ deleteConfirmPopupOpen: true, project: { id: projectId } });
    deleteConfirmPopupClose = () => this.setState({ deleteConfirmPopupOpen: false, project: {} });
    deleteConfirmPopupConfirm = () => {
        const projectId = _.get(this, "state.project.id");
        if(projectId) this.delete(projectId);
    };

    openEdit = project => {
        // console.log(project, 'toedit')
        this.setState({ openEdit: true, project });
    };

    delete = async projectId => {
        await ProjectService.delete(projectId);
        const projects = this.state.projects.filter(project => project.id !== projectId);
        this.setState({ projects, deleteConfirmPopupOpen: false });
    };

    handleSearchChange = (e, { value }) => {

        const projects = [ ...this.state.projects ];
        let search = { ...this.state.search };
        _.set(search, "value", value);

        // No value specified
        if(!value.length){
            _.set(search, "isLoading", false);
            _.set(search, "results", []);
            return this.setState({ search });
        }

        _.set(search, "isLoading", true);
        this.setState({ search }, () => {

            setTimeout(() => {

                search = { ...this.state.search };


                const results = projects.filter(project => {
                    const str = JSON.stringify(project).toLowerCase();
                    const regex = new RegExp(`${ _.trim( value.toLowerCase() ) }`, "gmi");
                    const results = str.match(regex);
                    return results && results.length
                });

                _.set(search, "isLoading", false);
                _.set(search, "results", results);


                return this.setState({ search });

            }, 200);

        });




    };

    async componentDidMount() {
        let projects = await ProjectService.list();
        projects = _.orderBy(projects, ["status"], ["desc"]);
        const clients = await ClientService.list();
        const users = await UserService.list();
        this.setState({ projects, clients, users });
    }

    render() {

        const { user, rootMethods: { setCurrency }, currencyInfo } = this.props;
        const { search, projects, open, openEdit, project, deleteConfirmPopupOpen, users, clients } = this.state;


        const projectUserOptions = users.map(user => {
            return {
                key: _.get(user, "Attributes.email"),
                text: _.get(user, "Attributes.name"),
                value: _.get(user, "Attributes.email"),
                image: { avatar: true, src: _.get(user, "Attributes.picture") }
            }
        }).filter(user => _.get(user, "text") );

        const projectClientOptions = clients.map(client => {
            return {
                key: _.get(client, "id"),
                text: _.get(client, "name"),
                value: _.get(client, "id")
            }
        }).filter(client => _.get(client, "text") );

        const projectStatusOptions = [
            {
                key: "opened",
                text: "Opened",
                value: "opened"
            },
            {
                key: "closed",
                text: "Closed",
                value: "closed"
            }
        ];

        return (

            <Fragment>

                {!projects.length ? <Dimmer page={true} active><Loader indeterminate size='medium'>Loading</Loader></Dimmer> : ''}

                <Header setCurrency={setCurrency} currencyInfo={currencyInfo} {...user} {...this.props} />
                <Main>

                    <Grid>

                        <Grid.Row>
                            <Grid.Column verticalAlign={'middle'}>

                                <ControlPanel>

                                    <Button animated positive onClick={this.open}>
                                        <Button.Content visible>New</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name='plus' />
                                        </Button.Content>
                                    </Button>


                                    <Input
                                        onChange={_.debounce(this.handleSearchChange, 500)}
                                        loading={_.get(search, "isLoading")}
                                        icon='search' placeholder='Search...' />

                                </ControlPanel>





                                {/*Create Modal*/}
                                <Modal onClose={this.close} closeOnEscape={true} open={open} centered={false}>
                                    <Modal.Header>Create new project</Modal.Header>
                                    <Modal.Content>
                                        <Form onSubmit={this.handleSubmit}>

                                            {/*<Form.Group widths='equal'>*/}
                                            <Form.Input onChange={this.handleChange} name='name' label='Name' placeholder='John Doe' required={true} />

                                            <Form.Field
                                                control={Select}
                                                options={projectClientOptions}
                                                label={{ children: 'Client', htmlFor: 'form-select-control-client' }}
                                                placeholder='Client'
                                                search
                                                searchInput={{ id: 'form-select-control-client' }}
                                                name="clientId"
                                                onChange={this.handleChange}
                                            />

                                            <Form.Field
                                                control={Dropdown}
                                                options={projectUserOptions}
                                                label={{ children: 'Users', htmlFor: 'form-select-control-user' }}
                                                placeholder='Users'
                                                multiple
                                                selection
                                                name="userIds"
                                                onChange={this.handleChange}
                                            />


                                            <Form.Field
                                                control={Dropdown}
                                                options={projectStatusOptions}
                                                label={{ children: 'Status', htmlFor: 'form-select-control-user' }}
                                                placeholder='Status'
                                                selection
                                                name="status"
                                                onChange={this.handleChange}
                                            />

                                            <Form.Input onChange={this.handleChange}
                                                        name='upworkChatLink'
                                                        label='Upwork Chat Link'
                                                        placeholder='https://www.upwork.com/messages/rooms/*' />

                                            <Form.Input onChange={this.handleChange}
                                                        name='telegramChatId'
                                                        label='Telegram Chat ID'
                                                        placeholder='123456' />

                                            <Form.Input onChange={this.handleChange}
                                                        name='telegramChatLink'
                                                        label='Telegram Chat Link'
                                                        placeholder='https://t.me/joinchat/*' />


                                            <Form.Button positive>Create</Form.Button>
                                        </Form>
                                    </Modal.Content>
                                </Modal>

                                {/*Edit Modal*/}
                                <Modal open={openEdit} closeOnEscape={true} onClose={this.close} centered={false}>
                                    <Modal.Header>Edit client</Modal.Header>
                                    <Modal.Content>
                                        <Form onSubmit={this.handleEditSubmit}>


                                            <Form.Input defaultValue={_.get(project, "name")} onChange={this.handleChange} name='name' label='Name' placeholder='John Doe' required={true} />

                                            <Form.Field
                                                control={Select}
                                                options={projectClientOptions}
                                                label={{ children: 'Client', htmlFor: 'form-select-control-client' }}
                                                placeholder='Client'
                                                search
                                                searchInput={{ id: 'form-select-control-client' }}
                                                name="clientId"
                                                onChange={this.handleChange}
                                                defaultValue={_.get(project, "clientId")}
                                            />

                                            <Form.Field
                                                control={Dropdown}
                                                options={projectUserOptions}
                                                label={{ children: 'Users', htmlFor: 'form-select-control-user' }}
                                                placeholder='Users'
                                                multiple
                                                selection
                                                name="userIds"
                                                onChange={this.handleChange}
                                                defaultValue={_.get(project, "userIds")}
                                            />

                                            <Form.Field
                                                control={Dropdown}
                                                options={projectStatusOptions}
                                                label={{ children: 'Status', htmlFor: 'form-select-control-user' }}
                                                placeholder='Status'
                                                selection
                                                name="status"
                                                defaultValue={_.get(project, "status")}
                                                onChange={this.handleChange}
                                            />

                                            <Form.Input onChange={this.handleChange}
                                                        defaultValue={_.get(project, "upwork.chatLink")}
                                                        name='upworkChatLink'
                                                        label='Upwork Chat Link'
                                                        placeholder='https://www.upwork.com/messages/rooms/*' />

                                            <Form.Input onChange={this.handleChange}
                                                        defaultValue={_.get(project, "telegram.chatId")}
                                                        name='telegramChatId'
                                                        label='Telegram Chat ID'
                                                        placeholder='123456' />

                                            <Form.Input onChange={this.handleChange}
                                                        defaultValue={_.get(project, "telegram.chatLink")}
                                                        name='telegramChatLink'
                                                        label='Telegram Chat Link'
                                                        placeholder='https://t.me/joinchat/*' />


                                            <Form.Button primary>Update</Form.Button>
                                        </Form>
                                    </Modal.Content>
                                </Modal>

                            </Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column>

                                <Confirm
                                    size={"tiny"}
                                    content={"Are you sure?"}
                                    confirmButton="Delete"
                                    open={deleteConfirmPopupOpen}
                                    onCancel={this.deleteConfirmPopupClose}
                                    onConfirm={this.deleteConfirmPopupConfirm}
                                />

                                <Card.Group stackable={true} itemsPerRow={3}>


                                    {_.get(search, "value").length && !_.get(search, "results").length ?
                                        <HeaderSemanticUI as='h2' icon textAlign='center'>
                                            <Icon name='server' circular />
                                            <HeaderSemanticUI.Content>Not Found</HeaderSemanticUI.Content>
                                        </HeaderSemanticUI>
                                        : ""}

                                    {_.get(search, "value").length && _.get(search, "results").length ? _.map(search.results, project => (
                                        this.renderCard(project)
                                    )) : ""}

                                    {!_.get(search, "value").length && projects.length ? _.map(projects, project => (
                                        this.renderCard(project)
                                    )) : ""}

                                </Card.Group>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Main>



            </Fragment>

        );
    }


}


export default connect(null)(ListAdmin);
