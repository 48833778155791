import React, { Component, Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Header, Main } from "../common";
import faker from "faker";
import _ from 'lodash';
import { ClientService } from "../../services";
import 'semantic-ui-css/semantic.min.css';


import {
    Icon,
    Loader,
    Modal,
    Button,
    Table,
    Grid,
    Header as HeaderSemanticUI,
    Form,
    Confirm,
    Card,
    Dimmer,
    Input
} from 'semantic-ui-react';
import moment from 'moment';
import accounting from "accounting";
import ReactJson from "react-json-view";




import styled from 'styled-components';

const ControlPanel = styled.div`
    display: flex;
    //justify-content: space-between;
`;

const StyledCard = styled(Card)`

    //min-width: 150px!important;
`;


export class List extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openEdit: false,
            openClientDeletePopup: false,
            client: {},
            clients: [],
            search: {
                isLoading: false,
                value: "",
                results: []
            }
        };

    }


    renderCard = client => {

        return (
            <StyledCard key={client.id} >
                <Card.Content>
                    <Card.Header>{client.name}</Card.Header>
                    <Card.Meta>{client.company}</Card.Meta>
                    {/*<Card.Description>{client.note || client.notes}</Card.Description>*/}
                </Card.Content>
                <Card.Content extra>

                    <Button.Group basic fluid>

                        <Button animated onClick={this.openEdit.bind(this, client)}>
                            <Button.Content visible>Edit</Button.Content>
                            <Button.Content hidden>
                                <Icon name='edit' />
                            </Button.Content>
                        </Button>

                        <Button animated onClick={this.deleteConfirmPopupOpen.bind( this, _.get(client, "id") )}>
                            <Button.Content visible>Delete</Button.Content>
                            <Button.Content hidden>
                                <Icon name='trash' />
                            </Button.Content>
                        </Button>

                    </Button.Group>

                </Card.Content>
            </StyledCard>
        );

    };

    handleChange = (e, { name, value }) => {
        let client = this.state.client;
        _.set(client, name, value);
        this.setState({ client });
    };

    handleSubmit = async () => {
        const client = await ClientService.add( this.state.client );
        const clients = this.state.clients;
        clients.push(client);
        this.setState({ clients, client: {}, open: false });
    };

    handleEditSubmit = async () => {

        const updatedClient = await ClientService.update( this.state.client );
        const clients = this.state.clients.map(client => client.id === updatedClient.id ? updatedClient : client );
        this.setState({ clients, client: {}, openEdit: false });
    };

    close = () => this.setState({ open: false, openEdit: false });
    open = () => this.setState({ open: true });
    deleteConfirmPopupOpen = clientId => this.setState({ clientDeleteConfirmPopupOpen: true, client: { id: clientId } });
    deleteConfirmPopupClose = () => this.setState({ clientDeleteConfirmPopupOpen: false, client: {} });
    deleteConfirmPopupConfirm = async () => {
        const clientId = _.get(this, "state.client.id");
        if(clientId) await this.deleteClient(clientId);
    };

    openEdit = client => {
        this.setState({ openEdit: true, client });
    };

    deleteClient = async clientId => {
        await ClientService.delete(clientId);
        const clients = _.get(this.state, "clients", []).filter(client => _.get(client, "id") !== clientId);
        this.setState({ clients, clientDeleteConfirmPopupOpen: false });
    };

    handleSearchChange = (e, { value }) => {

        const clients = [ ...this.state.clients ];
        let search = { ...this.state.search };
        _.set(search, "value", value);

        // No value specified
        if(!value.length){
            _.set(search, "isLoading", false);
            _.set(search, "results", []);
            return this.setState({ search });
        }

        _.set(search, "isLoading", true);
        this.setState({ search }, () => {

            setTimeout(() => {

                search = { ...this.state.search };

                const results = clients.filter(client => {
                    const str = JSON.stringify(client).toLowerCase();
                    const regex = new RegExp(`${ _.trim( value.toLowerCase() ) }`, "gmi");
                    const results = str.match(regex);
                    return results && results.length
                });

                _.set(search, "isLoading", false);
                _.set(search, "results", results);


                return this.setState({ search });

            }, 200);

        });




    };


    async componentDidMount() {
        const clients = await ClientService.list();
        this.setState({ clients });
    }

    render() {

        const { user, rootMethods: { setCurrency }, currencyInfo } = this.props;
        const { search, clients, open, openEdit, client, clientDeleteConfirmPopupOpen } = this.state;

        const searchResults = [];


        return (

            <Fragment>

                {!clients.length ? <Dimmer active page={true}><Loader indeterminate size='medium'>Loading</Loader></Dimmer> : ''}

                <Header setCurrency={setCurrency} currencyInfo={currencyInfo} {...user} {...this.props} />
                <Main>

                    <Grid>

                        <Grid.Row>
                            <Grid.Column>

                                <ControlPanel>

                                    <Button animated positive onClick={this.open}>
                                        <Button.Content visible>New</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name='plus' />
                                        </Button.Content>
                                    </Button>


                                    <Input
                                        onChange={_.debounce(this.handleSearchChange, 500)}
                                        loading={_.get(search, "isLoading")}
                                        icon='search' placeholder='Search...' />

                                </ControlPanel>




                                {/*Create Modal*/}
                                <Modal onClose={this.close} open={open} centered={false}>
                                    <Modal.Header>Create new client</Modal.Header>
                                    <Modal.Content>
                                        <Form onSubmit={this.handleSubmit}>

                                            {/*<Form.Group widths='equal'>*/}
                                            <Form.Input onChange={this.handleChange} name='name' label='Name' placeholder='John Doe' required={true} />
                                            <Form.Input onChange={this.handleChange} name='company' label='Company' placeholder='Intel' />
                                            <Form.Input onChange={this.handleChange} name='upworkChat' label='Upwork Chat' placeholder='*://upwork.com/*' />

                                            <Form.Input onChange={this.handleChange} name='email' label='Email' placeholder='john@domain.com' />
                                            <Form.Input onChange={this.handleChange} name='additionalEmails' label='Additional Emails' placeholder='john1@domain.com, john2@domain.com' />

                                            <Form.Input onChange={this.handleChange} name='country' label='Country' placeholder='USA' />
                                            <Form.Input onChange={this.handleChange} name='city' label='City' placeholder='San Francisco' />

                                            <Form.Input onChange={this.handleChange} name='phone' label='Phone' placeholder='+123456789' />
                                            <Form.Input onChange={this.handleChange} name='whatsApp' label='WhatsApp' placeholder='+123456789' />
                                            <Form.Input onChange={this.handleChange} name='skype' label='Skype' placeholder='username' />

                                            <Form.Input onChange={this.handleChange} name='telegram' label='Telegram' placeholder='username' />


                                            <Form.Input onChange={this.handleChange} name='linkedIn' label='LinkedIn' placeholder='https://linkedin.com/...' />
                                            <Form.Input onChange={this.handleChange} name='website' label='Website' placeholder='*://domain.com/*' />

                                            <Form.TextArea onChange={this.handleChange} name='notes' label='Notes' placeholder='Some kind words about client :)' />

                                            <Form.Button positive>Create</Form.Button>
                                        </Form>
                                    </Modal.Content>
                                </Modal>

                                {/*Edit Modal*/}
                                <Modal onClose={this.close} open={openEdit} centered={false}>
                                    <Modal.Header>Edit client</Modal.Header>
                                    <Modal.Content>
                                        <Form onSubmit={this.handleEditSubmit}>

                                            {/*<Form.Group widths='equal'>*/}
                                            <Form.Input defaultValue={_.get(client, "name")} onChange={this.handleChange} name='name' label='Name' placeholder='John Doe' required={true} />
                                            <Form.Input defaultValue={_.get(client, "company")} onChange={this.handleChange} name='company' label='Company' placeholder='Intel' />
                                            <Form.Input defaultValue={_.get(client, "upworkChat")} onChange={this.handleChange} name='upworkChat' label='Upwork Chat' placeholder='*://upwork.com/*' />

                                            <Form.Input defaultValue={_.get(client, "email")} onChange={this.handleChange} name='email' label='Email' placeholder='john@domain.com' />
                                            <Form.Input defaultValue={_.get(client, "additionalEmails")} onChange={this.handleChange} name='additionalEmails' label='Additional Emails' placeholder='john1@domain.com, john2@domain.com' />

                                            <Form.Input defaultValue={_.get(client, "country")} onChange={this.handleChange} name='country' label='Country' placeholder='USA' />
                                            <Form.Input defaultValue={_.get(client, "city")} onChange={this.handleChange} name='city' label='City' placeholder='San Francisco' />

                                            <Form.Input defaultValue={_.get(client, "phone")} onChange={this.handleChange} name='phone' label='Phone' placeholder='+123456789' />
                                            <Form.Input defaultValue={_.get(client, "whatsApp")} onChange={this.handleChange} name='whatsApp' label='WhatsApp' placeholder='+123456789' />
                                            <Form.Input defaultValue={_.get(client, "skype")} onChange={this.handleChange} name='skype' label='Skype' placeholder='username' />

                                            <Form.Input defaultValue={_.get(client, "telegram")} onChange={this.handleChange} name='telegram' label='Telegram' placeholder='username' />


                                            <Form.Input defaultValue={_.get(client, "linkedIn")} onChange={this.handleChange} name='linkedIn' label='LinkedIn' placeholder='https://linkedin.com/...' />
                                            <Form.Input defaultValue={_.get(client, "website")} onChange={this.handleChange} name='website' label='Website' placeholder='*://domain.com/*' />

                                            <Form.TextArea defaultValue={_.get(client, "notes")} onChange={this.handleChange} name='notes' label='Notes' placeholder='Some kind words about client :)' />

                                            <Form.Button primary>Update</Form.Button>
                                        </Form>
                                    </Modal.Content>
                                </Modal>

                            </Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column>

                                <Confirm
                                    size={"tiny"}
                                    content={"Are you sure?"}
                                    confirmButton="Delete"
                                    open={clientDeleteConfirmPopupOpen}
                                    onCancel={this.deleteConfirmPopupClose}
                                    onConfirm={this.deleteConfirmPopupConfirm}
                                />

                                <Card.Group stackable={true} itemsPerRow={3}>


                                    {_.get(search, "value").length && !_.get(search, "results").length ?
                                        <HeaderSemanticUI as='h2' icon textAlign='center'>
                                            <Icon name='server' circular />
                                            <HeaderSemanticUI.Content>Not Found</HeaderSemanticUI.Content>
                                        </HeaderSemanticUI>
                                        : ""}

                                    {_.get(search, "value").length && _.get(search, "results").length ? _.map(search.results, client => (
                                        this.renderCard(client)
                                    )) : ""}

                                    {!_.get(search, "value").length && clients.length ? _.map(clients, client => (
                                        this.renderCard(client)
                                    )) : ""}

                                </Card.Group>


                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Main>



            </Fragment>

        );
    }


}


export default connect(null)(List);