import React, { Component, Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Header, Main } from "../common";
import faker from "faker";
import _ from 'lodash';
import { ProjectService, UserService, ClientService } from "../../services";
import 'semantic-ui-css/semantic.min.css';
import accounting from 'accounting';

import {
    Icon,
    Feed,
    Loader,
    Statistic,
    Image,
    Select,
    Button,
    List,
    Grid,
    Header as HeaderSemanticUI,
    Form,
    Confirm,
    Card,
    Dimmer, Input, Container, Label
} from 'semantic-ui-react';



import styled from 'styled-components';

const ControlPanel = styled.div`
    display: flex;
    //justify-content: space-between;
    
`;

const StyledCard = styled(Card)`
    //display: flex;
    .statusIndicatorCircle{
      margin-right: 5px!important;
    }
`;




export class ListAdmin extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            closeOnEscape: true,
            openEdit: false,
            deleteConfirmPopupOpen: false,
            project: {},
            projects: [],
            users: [],
            clients: [],
            search: {
                isLoading: false,
                value: "",
                results: []
            }
        };

    }


    renderCard = project => {
        const { currencyInfo } = this.props;
        const selectedCurrencyCode = _.get(currencyInfo, "selectedCurrency", "").toLowerCase();

        const selectedCurrencyRate = _.get(currencyInfo, `currencies[${selectedCurrencyCode}].rate`, 1);
        const selectedCurrencySymbol = _.get(currencyInfo, `currencies[${selectedCurrencyCode}].symbol`, "$");

        const totalEarned = accounting.formatMoney(project.totalEarned * selectedCurrencyRate, selectedCurrencySymbol, 2, ",", ".");

        const upworkChatLink = _.get(project, "upwork.chatLink");
        const telegramChatLink = _.get(project, "telegram.chatLink");

        return (
            <StyledCard key={project.id} >

                <Card.Content>

                    <Card.Header>
                        <Label circular color={project.status === "opened" ? "green" : "grey"} empty className={"statusIndicatorCircle"}/> {project.name}
                    </Card.Header>
                    {/*<Card.Meta></Card.Meta>*/}
                    <Card.Description>



                        <Feed>


                            {_.get(project, "users", []).map(user => (

                                <Feed.Event key={_.get(user, "email")} >
                                    <Feed.Label image={_.get(user, "picture")} />

                                    <Feed.Content>
                                        <Feed.Summary content={_.get(user, "name")} />
                                    </Feed.Content>

                                </Feed.Event>

                            ))}

                        </Feed>



                    </Card.Description>
                </Card.Content>


                {/*{upworkChatLink ? <Card.Content extra>*/}
                {/*    <a target={"_blank"} href={upworkChatLink}><Icon name='discussions' /> Upwork</a>*/}
                {/*</Card.Content> : ""}*/}

                {telegramChatLink ? <Card.Content extra>
                    <a target={"_blank"} href={telegramChatLink}><Icon name='telegram' /> Telegram</a>
                </Card.Content> : ""}

                <Card.Content extra>
                    <Icon name='money' /> {totalEarned}
                </Card.Content>
                <Card.Content extra>
                    <Icon name='user' /> {_.get(project, "client.name")}
                </Card.Content>
            </StyledCard>
        );

    };

    handleChange = (e, { name, value }) => {
        let project = this.state.project;
        _.set(project, name, value);
        this.setState({ project });
    };

    handleSubmit = async () => {
        const project = await ProjectService.add( this.state.project );
        const projects = this.state.projects;
        projects.push(project);
        this.setState({ projects, project: {}, open: false });
    };

    handleEditSubmit = async () => {
        const project = this.state.project;
        const updatedProject = await ProjectService.update( _.omit(project, ["users"]) );
        const projects = this.state.projects.map(project => project.id === updatedProject.id ? updatedProject : project );
        this.setState({ projects, project: {}, openEdit: false });
    };

    close = () => this.setState({ open: false, openEdit: false });
    open = () => this.setState({ open: true });
    deleteConfirmPopupOpen = projectId => this.setState({ deleteConfirmPopupOpen: true, project: { id: projectId } });
    deleteConfirmPopupClose = () => this.setState({ deleteConfirmPopupOpen: false, project: {} });
    deleteConfirmPopupConfirm = () => {
        const projectId = _.get(this, "state.project.id");
        if(projectId) this.delete(projectId);
    };

    openEdit = project => {
        this.setState({ openEdit: true, project });
    };

    delete = async projectId => {
        await ProjectService.delete(projectId);
        const projects = this.state.projects.filter(project => project.id !== projectId);
        this.setState({ projects, deleteConfirmPopupOpen: false });
    };

    handleSearchChange = (e, { value }) => {

        const projects = [ ...this.state.projects ];
        let search = { ...this.state.search };
        _.set(search, "value", value);

        // No value specified
        if(!value.length){
            _.set(search, "isLoading", false);
            _.set(search, "results", []);
            return this.setState({ search });
        }

        _.set(search, "isLoading", true);
        this.setState({ search }, () => {

            setTimeout(() => {

                search = { ...this.state.search };

                const results = projects.filter(project => {
                    const str = JSON.stringify(project).toLowerCase();
                    const regex = new RegExp(`${ _.trim( value.toLowerCase() ) }`, "gmi");
                    const results = str.match(regex);
                    return results && results.length
                });

                _.set(search, "isLoading", false);
                _.set(search, "results", results);


                return this.setState({ search });

            }, 200);

        });




    };

    async componentDidMount() {
        let projects = await ProjectService.list();
        projects = _.orderBy(projects, ["status"], ["desc"]);
        projects = projects.filter(project => _.get(project, "name") !== "BTS");
        this.setState({ projects });


    }

    render() {

        const { user, rootMethods: { setCurrency }, currencyInfo } = this.props;
        const { search, projects, open, openEdit, project, deleteConfirmPopupOpen, users, clients } = this.state;


        return (

            <Fragment>

                {!projects.length ? <Dimmer page={true} active><Loader indeterminate size='medium'>Loading</Loader></Dimmer> : ''}

                <Header setCurrency={setCurrency} currencyInfo={currencyInfo} {...user} {...this.props} />
                <Main>

                    <Grid>

                        <Grid.Row>
                            <Grid.Column verticalAlign={'middle'}>

                                <ControlPanel>


                                    <Input
                                        onChange={_.debounce(this.handleSearchChange, 500)}
                                        loading={_.get(search, "isLoading")}
                                        icon='search' placeholder='Search...' />

                                </ControlPanel>


                            </Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column>

                                <Confirm
                                    size={"tiny"}
                                    content={"Are you sure?"}
                                    confirmButton="Delete"
                                    open={deleteConfirmPopupOpen}
                                    onCancel={this.deleteConfirmPopupClose}
                                    onConfirm={this.deleteConfirmPopupConfirm}
                                />

                                <Card.Group stackable={true} itemsPerRow={3}>


                                    {_.get(search, "value").length && !_.get(search, "results").length ?
                                        <HeaderSemanticUI as='h2' icon textAlign='center'>
                                            <Icon name='server' circular />
                                            <HeaderSemanticUI.Content>Not Found</HeaderSemanticUI.Content>
                                        </HeaderSemanticUI>
                                         : ""}


                                    {_.get(search, "value").length && _.get(search, "results").length ? _.map(search.results, project => (
                                        this.renderCard(project)
                                    )) : ""}

                                    {!_.get(search, "value").length && projects.length ? _.map(projects, project => (
                                        this.renderCard(project)
                                    )) : ""}

                                </Card.Group>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Main>



            </Fragment>

        );
    }


}


export default connect(null)(ListAdmin);
