import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Dimmer } from 'semantic-ui-react';

import retry from "async-retry";
import Amplify, { Hub, Auth } from 'aws-amplify';
import { AWSConfig } from '../../config';
Amplify.configure(AWSConfig);


export class OAuth extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }



    async componentDidMount () {

        Hub.listen("auth", ({ payload: { event, data } }) => {
            if( event === "signIn") return window.location.replace("/finance");
        });

        const self = this;

        try{
            await retry(async bail => {
                const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
                return user;
            }, {
                retries: 3
            });
            return window.location.replace("/finance");

        } catch (e) {
            return await Auth.federatedSignIn();
        }

    }


    render() {


        return <Dimmer active><Loader size='medium'>Loading</Loader></Dimmer>;
    }


}

export default connect(null)(OAuth);