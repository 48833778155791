// import { baseURL } from '../config';
import _ from "lodash";
import { OAuthService } from "./OAuthService";

import rp from "request-promise";
const baseURL = "https://dev.botsculptors.com";


class User {


    list(){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'GET',
                url: `${baseURL}/users/list`,
                headers: {
                    authorization: idToken
                },
                json: true
            };

            rp(options)
                .then(res => {
                    const users = (res || []).map(user => {
                        const attributes = {};

                        _.get(user, "Attributes", [])
                            .forEach(attribute => {

                                if( _.get(attribute, "Name") === "custom:metadata" ){

                                    let metadata = {};
                                    try{
                                        metadata = JSON.parse( _.get(attribute, "Value") );
                                    } catch (e) {
                                        metadata = {};
                                    }
                                    _.set( attributes, "metadata", metadata );
                                } else{
                                    _.set( attributes, _.get(attribute, "Name"), _.get(attribute, "Value") );
                                }


                            });

                        _.set(user, "Attributes", attributes);

                        return user;

                    });

                    return resolve(users);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }

}

export const UserService = new User();
