import rp from "request-promise";
import _ from "lodash";
import { OAuthService } from "./"
import { Unauthorized } from "http-errors";
import moment from "moment";
import axios from "axios";

class Transaction{

    list(filter){

        return new Promise(async (resolve, reject) => {
           try{

               const session = await OAuthService.getSession();
               const token = _.get(session, "idToken.jwtToken");
               if(!session || !token) return reject( new Unauthorized() );

               const dateFrom = _.get(filter, "dateFrom") || "2018-03-27";
               const dateTo = _.get(filter, "dateTo") || moment().add(1, "year").format("YYYY-MM-DD");

               rp({
                   method: "GET",
                   url: `https://dev.botsculptors.com/transactions/list?dateFrom=${dateFrom}&dateTo=${dateTo}`,
                   headers: {
                       authorization: token
                   },
                   json: true
               })
                   .then(res => {
                       return resolve(res);
                   })
                   .catch(e => {
                       return reject(e);
                   })


           } catch (e) {
               return reject(e);
           }
        });

    }
    listCurrencyRates() {

        return new Promise(async (resolve, reject) => {

            try{

                const storedCurrencies = localStorage.getItem('currencyInfo') ? JSON.parse( localStorage.getItem('currencyInfo') ) : null;
                const isUpdatedToday = storedCurrencies ? moment().isSame(moment(storedCurrencies.updatedAt), 'day') : null;
                const currencyTemplateObject = (eur, gel, rub) => {
                    return {
                        usd: {
                            rate: 1,
                            symbol: '$'
                        }, eur: {
                            rate: eur,
                            symbol: '€'
                        }, gel: {
                            rate: gel,
                            symbol: '₾'
                        }, rub: {
                            rate: rub,
                            symbol: '₽'
                        }
                    };
                };

                // check for last update
                if(isUpdatedToday){
                    return resolve(storedCurrencies)
                } else{

                    const currencies = await axios.get(`https://openexchangerates.org/api/latest.json?app_id=f867c8a77db84cb6926c1f1382be7e5b`)
                        .then(response => {
                            // console.log(response);
                            const rates = _.get(response, "data.rates");
                            const USD_EUR = _.get(rates, "EUR", 0);
                            const USD_GEL = _.get(rates, "GEL", 0);
                            const USD_RUB = _.get(rates, "RUB", 0);
                            return currencyTemplateObject(USD_EUR, USD_GEL, USD_RUB);
                        })
                        .catch(error => {
                            console.log(error);
                            return currencyTemplateObject(0, 0, 0);
                        });


                    const currencyInfo = {
                        updatedAt: moment().toISOString(),
                        currencies,
                        selectedCurrency: storedCurrencies ? storedCurrencies.selectedCurrency : 'USD'
                    };
                    localStorage.setItem('currencyInfo', JSON.stringify(currencyInfo));

                    return resolve(currencyInfo);


                }

            } catch (e) {
                return reject(e);
            }

        });


    };

}

export const TransactionService = new Transaction();