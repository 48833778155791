module.exports = {
    company: "https://www.upwork.com/agencies/~01db6745cb7a665aba",
    baseURL: "https://api.botsculptors.com",

    AWSConfigDev: {
        Auth: {
            region: "us-east-1",
            userPoolId: "us-east-1_tT7pvT2S4",
            userPoolWebClientId: "u4mvngtv12qj7f1s6k62k6i81",
            mandatorySignIn: false,
            cookieStorage: {
                domain: "localhost",
                path: "/",
                expires: 365,
                secure: false
            },
            oauth: {
                domain: "oauth.botsculptors.com",
                scope: ["openid", "profile", "aws.cognito.signin.user.admin", "email"],
                redirectSignIn: "https://localhost:3000/oauth/sign-in",
                redirectSignOut: "https://localhost:3000/oauth/sign-out",
                responseType: "code"
            }
        }
    },
    AWSConfig: {
        Auth: {
            region: "us-east-1",
            userPoolId: "us-east-1_tT7pvT2S4",
            userPoolWebClientId: "u4mvngtv12qj7f1s6k62k6i81",
            mandatorySignIn: false,
            cookieStorage: {
                domain: "dashboard.botsculptors.com",
                path: "/",
                expires: 365,
                secure: false
            },
            oauth: {
                domain: "oauth.botsculptors.com",
                scope: ["openid", "profile", "aws.cognito.signin.user.admin", "email"],
                redirectSignIn: "https://dashboard.botsculptors.com/oauth/sign-in",
                redirectSignOut: "https://dashboard.botsculptors.com/oauth/sign-out",
                responseType: "code"
            }
        }
    }
};
