import React, { Component } from 'react';

import { Redirect, Switch, Route } from 'react-router-dom';
import { Provider, connect } from 'react-redux';


import { ConnectedRouter } from 'react-router-redux';

import Clients from "./clients";
import Projects from "./projects";
import OAuth from "./pages/OAuth";
import Finance from "./pages/Finance";
import FinanceAdmin from "./pages/FinanceAdmin";
// import ClientsAdmin from "./pages/ClientsAdmin";
// import Projects from "./pages/Projects";
// import ProjectsAdmin from "./pages/ProjectsAdmin";

import {OAuthService, TransactionService } from "../services";

import {
    Loader,
    Dimmer
} from 'semantic-ui-react';

const _ = require("lodash");

export class App extends Component{

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            userGroups: [],
            isLoading: window.location.pathname !== "/oauth/sign-in"
        };
        this.setCurrency = this.setCurrency.bind(this);
    }

    async setCurrency(currency) {

        try{

            let storedCurrencies = localStorage.getItem('currencyInfo') ? JSON.parse( localStorage.getItem('currencyInfo') ) : null;
            if(storedCurrencies && currency){
                storedCurrencies.selectedCurrency = currency;
                localStorage.setItem('currencyInfo', JSON.stringify(storedCurrencies));
                const currencyInfo = await TransactionService.listCurrencyRates();
                this.setState( { currencyInfo } );
            }

        } catch (e) {
            console.log(e);
        }

    }

    async componentDidMount(){

        const isSignInPage = window.location.pathname === "/oauth/sign-in";
        if(isSignInPage) return this.setState({ isLoading: false });

        const user = await OAuthService.getUser();
        if(!user) return OAuthService.federatedSignIn();
        // console.log(user)

        const isSuperAdmin = _.get(user, "signInUserSession.idToken.payload.cognito:groups", []).includes("SuperAdmins");
        const isDeveloper = _.get(user, "signInUserSession.idToken.payload.cognito:groups", []).includes("Developers");

        const currencyInfo = await TransactionService.listCurrencyRates();
        this.setState({ user: {...user, isSuperAdmin, isDeveloper}, isLoading: false, currencyInfo });

    }


    render(){

        let { store, history } = this.props;
        const { user, isLoading, currencyInfo } = this.state;
        const isSuperAdmin = _.get(user, "isSuperAdmin");
        const isDeveloper = _.get(user, "isDeveloper");

        const rootMethods = {
            setCurrency: this.setCurrency
        };

        if(!user && isLoading) return <Dimmer page={true} active><Loader size='medium'>Loading</Loader></Dimmer>;

        let financeRoute;
        let clientRoute;
        let projectRoute;


        if(isSuperAdmin){
            financeRoute = <Route exact path='/finance' render={() => <FinanceAdmin
                {...this.props}
                user={user}
                currencyInfo={currencyInfo}
                rootMethods={rootMethods}
            />} />;
            clientRoute = <Route exact path='/clients/list' render={() => <Clients.List
                {...this.props}
                user={user}
                currencyInfo={currencyInfo}
                rootMethods={rootMethods}
            />}  />;

            projectRoute = <Route exact path='/projects/list' render={() => <Projects.ListAdmin
                {...this.props}
                user={user}
                currencyInfo={currencyInfo}
                rootMethods={rootMethods}
            />}  />;
        }
        if(isDeveloper){
            financeRoute = <Route exact path='/finance' render={() => <Finance
                {...this.props}
                user={user}
                currencyInfo={currencyInfo}
                rootMethods={rootMethods}
            />} />;

            projectRoute = <Route exact path='/projects/list' render={() => <Projects.List
                {...this.props}
                user={user}
                currencyInfo={currencyInfo}
                rootMethods={rootMethods}
            />}  />;

        }

        return (
            <Provider store={store}>

                <ConnectedRouter history={history}>

                    <Switch>
                        <Route exact path='/' render={() => <Redirect to="/finance" /> }  />

                        {financeRoute || ""}
                        {clientRoute || ""}
                        {projectRoute || ""}

                        <Route exact path='/oauth/sign-in' render={() => <OAuth {...this.props} />}  />

                        {/*<Route exact path='/clients/add' render={() => <Clients.Add {...this.props} />}  />*/}


                        {/*<Route exact path='/projects' render={() => auth.status ? (!auth.isAdmin ? <Projects {...this.props} to="/finance" /> : <ProjectsAdmin {...this.props} />) : <Redirect to="/login" />} />*/}

                        {/*<Route exact path='/clients' render={() => auth.status ? (!auth.isAdmin ? <Redirect to="/login" /> : <ClientsAdmin {...this.props} />) : <Redirect to="/login" />} />*/}

                        {/*<Redirect to="/" />*/}

                    </Switch>

                </ConnectedRouter>

            </Provider>
        )

    }
}

export default connect(null)(App);