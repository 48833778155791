import React from 'react';
import styled from "styled-components";

const Wrapper = styled.main`
  display: flex;
  justify-content: center;
  margin-top: 70px;
  
  ${({theme}) => theme.mobile`
        margin-top: 330px;
    `}
    
    ${({theme}) => theme.tablet`
        margin-top: 330px;
    `}
`;
const MainIn = styled.div`
    width: 100%;
  
    padding: 0 ${({theme}) => theme.contentPaddingDesktop};
      
      
    ${({theme}) => theme.mobile`

        padding: 0 ${props => props.theme.contentPaddingMobile};
    `}
      
    
    ${({theme}) => theme.tablet`
        padding: 0 ${props => props.theme.contentPaddingTablet};
    `}
`;



export const Main = props => {

    const { children } = props;

    return (

        <Wrapper>
            <MainIn>
                {children}
            </MainIn>
        </Wrapper>

    );

};

