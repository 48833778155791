import _ from "lodash";
import Amplify, { Auth } from 'aws-amplify';
import { AWSConfig } from "../config";
Amplify.configure(AWSConfig);


class OAuth{

    async signOut(){
        return await Auth.signOut()
    }

    getSession(){
        return new Promise(async resolve => {
            try{
                const session = await Auth.currentSession();
                return resolve(session);
            } catch(e){
                return resolve(null);
            }
        });
    }

    getUser(){
        return new Promise(async resolve => {
            try{
                let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
                let metadata = {};
                try{ metadata = JSON.parse( _.get(user, "attributes.custom:metadata", {}) ); } catch (e) {}
                _.set(user, "attributes.metadata", metadata);
                // console.log(user)
                return resolve(user);
            } catch(e){
                console.log(e);
                return resolve(null);
            }
        });
    }

    federatedSignIn(){
        return Auth.federatedSignIn();
    }


    getUserInfo(){
        return new Promise(async resolve => {
            try{
                const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
                return resolve(user);
            } catch(e){
                console.log(e);
                return resolve(null);
            }
        });
    }


}

export const OAuthService = new OAuth();