// import { baseURL } from '../config';
import _ from "lodash";
import { OAuthService } from "./OAuthService";

import rp from "request-promise";
const baseURL = "https://dev.botsculptors.com";


class Client {

    add(client){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'POST',
                url: `${baseURL}/clients/add`,
                headers: {
                    authorization: idToken
                },
                body: client,
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }
    update(client){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'PUT',
                url: `${baseURL}/clients/update`,
                headers: {
                    authorization: idToken
                },
                body: client,
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }
    delete(clientId){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'DELETE',
                url: `${baseURL}/clients/delete`,
                headers: {
                    authorization: idToken
                },
                body: {clientId},
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }
    list(){

        return new Promise(async (resolve, reject) => {

            const session = await OAuthService.getSession();
            const idToken = _.get(session, "idToken.jwtToken");


            const options = {
                method: 'GET',
                url: `${baseURL}/clients/list`,
                headers: {
                    authorization: idToken
                },
                json: true
            };

            rp(options)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {

                    return reject(err);
                })


        });

    }

}

export const ClientService = new Client();
